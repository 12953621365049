// ** Initial State
const initialState = {
    allStaffs: [],
    allRoles: [],
    allDepartments: [],
    allDesignations: [],
    data: [],
    isFetching: false,
    selectedData: null,
    selectedRole: null
  }
  
  const agencies = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_STAFFS':
        return { ...state, allStaffs: action.payload, allData: action.payload }
      case 'GET_STAFFS':
        return {  ...state, data: action.payload }
      case 'GET_STAFF':
        return { ...state, selectedUser: action.selectedUser }
      case 'ADD_STAFF':
        return { ...state }
      case 'DELETE_STAFF':
        return { ...state }
      case 'GET_ALL_ROLES':
        return { ...state, allRoles: action.payload }
      case 'GET_ROLE':
        return { ...state, selectedRole: action.payload, isFetching: action.isFetching }
      case 'GET_ALL_DEPARTMENTS':
        return { ...state, allDepartments: action.payload }
      case 'GET_ALL_DESIGNATIONS':
        return { ...state, allDesignations: action.payload }
      default:
        return { ...state }
    }
  }
  export default agencies
  