// ** Initial State
const initialState = {
    flightList: [],
    isFetching: false,
    flightInfo: null,
    searchParams: null,
    seatQty: [],
    bookingList: [],
    flightBookingList: [],
    hotelBookingList: [],
    transfersBookingList: [],
    activitiesBookingList: [],
    packagesBookingList: [],
    hotelBookingDetails: null,
    selectedFlightBooking: null,
    selectedHotelBooking: null,
    selectedTransfersBooking: null,
    selectedActivitesBooking: null,
    selectedPackageBooking: null,
    debugInfo: null,
    activityInfo: null
}

const booking = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FLIGHT_SEARCH':
            return { ...state, flightList: action.payload, searchParams: action.search, isFetching: action.fetching }
        case 'GET_FLIGHT_VALIDATE':
            return { ...state, flightInfo: action.payload, flightList: [], isFetching: action.fetching, seatQty: action.seat, debugInfo: action.debugInfo }
        case 'GET_BOOKING_LIST':
            return { ...state, bookingList: action.payload }   
        case 'GET_FLIGHT_BOOKING_LIST':
            return { ...state, flightBookingList: action.payload }
        case 'GET_HOTEL_BOOKING_LIST':
            return { ...state, hotelBookingList: action.payload }
        case 'GET_TRANSFERS_BOOKING_LIST':
            return { ...state, transfersBookingList: action.payload }
        case 'GET_ACTIVITIES_BOOKING_LIST':
            return { ...state, activitiesBookingList: action.payload }
        case 'GET_PACKAGES_BOOKING_LIST':
            return { ...state, packagesBookingList: action.payload }
        case 'HOTEL_REVALIDATE':
            return { ...state, hotelBookingDetails: action.payload, isFetching: action.isFetching }
        case 'GET_ACTIVITY_VALIDATE':
            return { ...state, activityInfo: action.payload, isFetching: action.isFetching }
        default:
            return { ...state }
    }
}

export default booking