// ** Initial State
const initialState = {
    allRegions: [],
    allCurrencies: [],
    allLanguages: [],
    allCategories: [],
    allCommissions: [],
    selectedCommission: null,
    allCity:[],
    data: [],
    isFetching: false,
    allMarkUps: [],
    selectedMarkUp: null
  }
  
  const global = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_REGIONS':
        return { ...state, allRegions: action.payload }
      case 'GET_ALL_CURRENCIES':
        return { ...state, allCurrencies: action.payload }
      case 'GET_ALL_LANGUAGES':
        return { ...state, allLanguages: action.payload }
      case 'GET_ALL_MARKUPS':
        return { ...state, allMarkUps: action.payload }
      case 'GET_ALL_COMMISSIONS':
        return { ...state, allCommissions: action.payload }
      case 'GET_COMMISSION':
        return { ...state, selectedCommission: action.payload }
      case 'GET_MARKUP':
        return { ...state, selectedMarkUp: action.payload }
      case 'GET_CATEGORIES':
        return { ...state, allCategories: action.payload }   
      default:
        return { ...state }
    }
  }
  export default global
  