import mock from '../mock'

const data = {
  // blog list
  blogList: [
    {
      img: require('@src/assets/images/slider/02.jpg').default,
      title: 'Regent Airways Flight Tickets',
      id: 1,
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      blogPosted: 'Jan 10, 2020',
      tags: ['Quote', 'Fashion'],
      excerpt: 'Travelling for adventure or work, it’s always a privilege to have comfort in it. When flying to...',
      comment: 76
    },
    {
      img: require('@src/assets/images/slider/06.jpg').default,
      title: 'Exploring Indonesian Island Bali',
      id: 2,
      avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
      userFullName: 'Jorge Griffin',
      blogPosted: 'Jan 10, 2020',
      tags: ['Gaming', 'Video'],
      excerpt: 'Indonesian island Bali is known for its forested volcanic mountains, picturesque rice paddies...',
      comment: 2100
    },
    {
      img: require('@src/assets/images/slider/04.jpg').default,
      title: 'Bandarban- Heaven in the clouds',
      id: 3,
      avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
      userFullName: 'Claudia Neal',
      blogPosted: 'Jan 10, 2020',
      tags: ['Gaming', 'Food'],
      excerpt: 'Bandarban, one of the most pristine yet developed tour attractions of the country. One of the ...',
      comment: 243
    },
    {
      img: require('@src/assets/images/slider/03.jpg').default,
      title: 'The Land of Snow and Ancient Relics',
      id: 4,
      avatar: require('@src/assets/images/portrait/small/avatar-s-14.jpg').default,
      userFullName: 'Fred Boone',
      blogPosted: 'Jan 10, 2020',
      tags: ['Video'],
      excerpt: 'Bangladeshis it is a real opportunity to enjoy the variations of mother nature at a very cheap...',
      comment: 10
    },
    {
      img: require('@src/assets/images/slider/09.jpg').default,
      title: 'The Land of the White Elephant',
      id: 5,
      avatar: require('@src/assets/images/portrait/small/avatar-s-13.jpg').default,
      userFullName: 'Billy French',
      blogPosted: 'Jan 10, 2020',
      tags: ['Quote', 'Fashion'],
      excerpt: 'Considered to be the land of mysterious delight Thailand has a lot to offer with regards to...',
      comment: 319
    }
  ],

  // sidebar
  blogSidebar: {
    recentPosts: [
      {
        img: require('@src/assets/images/banner/banner-22.jpg').default,
        title: 'Why Should Forget Facebook?',
        id: 7,
        createdTime: 'Jan 14 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-27.jpg').default,
        title: 'Publish your passions, your way',
        id: 8,
        createdTime: 'Mar 04 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-39.jpg').default,
        title: 'The Best Ways to Retain More',
        id: 9,
        createdTime: 'Feb 18 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-35.jpg').default,
        title: 'Share a Shocking Fact or Statistic',
        id: 10,
        createdTime: 'Oct 08 2020'
      }
    ],
    categories: [
      { category: 'Fashion', icon: 'Watch' },
      { category: 'Food', icon: 'ShoppingCart' },
      { category: 'Gaming', icon: 'Command' },
      { category: 'Quote', icon: 'Hash' },
      { category: 'Video', icon: 'Video' }
    ]
  },

  // detail
  blogDetail: {
    blog: {
      img: require('@src/assets/images/banner/banner-12.jpg').default,
      title: 'Regent Airways Flight Tickets',
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      createdTime: 'Jan 10, 2020',
      tags: ['Travel', 'Flights'],
      content:
        '<p>Regent Airways started its commercial flights since 10 November 2010 with the commitment of quality and comfortable flying. Initially Regent got on board with two Dash 8 Q300 aircraft. Regent proves to be on mark with the industry standard reliability and got popularity. The airline manged to set higher standard among other compete-ting carrier in the Bangladeshi market in case of safety standard and schedule reliability.</p>',
      comments: 19100,
      bookmarked: 139
    },
    comments: [
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        userFullName: 'Chad Alexander',
        commentedAt: 'May 24, 2020',
        commentText:
          'A variation on the question technique above, the multiple-choice question great way to engage your reader.'
      }
    ]
  },

  // edit
  blogEdit: {
    avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
    userFullName: 'Chad Alexander',
    createdTime: 'May 24, 2020',
    blogTitle: 'The Best Features Coming to iOS and Web design',
    blogCategories: [
      { value: 'fashion', label: 'Fashion' },
      { value: 'gaming', label: 'Gaming' }
    ],
    slug: 'the-best-features-coming-to-ios-and-web-design',
    status: 'Published',
    excerpt:
      '<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>',
    featuredImage: require('@src/assets/images/slider/03.jpg').default
  }
}

mock.onGet('/blog/list/data').reply(() => [200, data.blogList])
mock.onGet('/blog/list/data/sidebar').reply(() => [200, data.blogSidebar])
mock.onGet('/blog/list/data/detail').reply(() => [200, data.blogDetail])
mock.onGet('/blog/list/data/edit').reply(() => [200, data.blogEdit])
