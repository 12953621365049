import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  roles: [
    {
      id: 1,
      role: 'editor',
      createdOn: 'June 23, 2021 2:09 AM',
      members: 5,
      status: 'inactive'
    },
    {
      id: 2,
      role: 'author',
      createdOn: 'June 23, 2021 2:09 AM',
      members: 10,
      status: 'pending'
    },
    {
      id: 3,
      role: 'maintainer',
      createdOn: 'June 23, 2021 2:09 AM',
      members: 3,
      status: 'active'
    },
    {
      id: 4,
      role: 'subscriber',
      createdOn: 'June 23, 2021 2:09 AM',
      members: 5,
      status: 'pending'
    },
    {
      id: 4,
      role: 'admin',
      createdOn: 'June 23, 2021 2:09 AM',
      members: 8,
      status: 'inactive'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/role/list/all-data').reply(200, data.roles)

// POST: Add new user
mock.onPost('/apps/users/add-user').reply(config => {
  // Get event from post data
  const user = JSON.parse(config.data)

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.unshift(user)

  return [201, { user }]
})

// GET Updated DATA
mock.onGet('/api/role/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.roles.filter(
    user =>
      user.role === (role || user.role) &&
      user.status === (status || user.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      roles: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET ROLE
mock.onGet('/api/roles/role').reply(config => {
  const { id } = config
  const role = data.roles.find(i => i.id === id)
  return [200, { role }]
})

// DELETE: Deletes User
mock.onDelete('/apps/users/delete').reply(config => {
  // Get user id from URL
  let userId = config.id

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(t => t.id === userId)
  data.users.splice(userIndex, 1)

  return [200]
})
